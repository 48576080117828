import { installationFields } from "./inputs";
import ButtonItem from "../../ButtonItem";
import ButtonConfItem from "../../ButtonConfItem";
import { Form } from "antd";
import React, { useEffect, useState } from "react";
import TitleWithBack from "../../../containers/TitleWithBack";
import {
  getInstallation,
  UpdateInstallation as updateInstallationApi,
} from "../../../api/services/installationsService";
import { Installation } from "../../../api/requests/installationsService";
import { useHistory } from "react-router-dom";
import CardOption from "./CardOption";
import FormInput from "../../input/FormInput";
import { updateInstallationTitle } from "../title";
import OperationResult from "../../OperationResult";

type UpdateInstallationProps = {
  installationId: string;
};

const UpdateInstallation: React.FC<UpdateInstallationProps> = ({
  installationId,
}) => {
  const history = useHistory();

  const [width, setWidth] = useState<number>(window.innerWidth);
  const [status, setStatus] = useState<"success" | "error" | null>(null);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const [selectedInstallation, setSelectedInstallation] =
    useState<Installation | null>(null);
  useEffect(() => {
    getInstallation({ id: installationId }).then((res) => {
      if (res && res.installation) {
        setSelectedInstallation(res.installation);
      } else {
        setStatus("error");
      }
    });
  }, []);

  const [form] = Form.useForm();

  const submit = () => {
    form.validateFields().then((values) => {
      updateInstallationApi({
        name: values.name,
        id: selectedInstallation?.id ?? "",
        description: values.description,
      }).then((res) => {
        if (res && !res.err) {
          setStatus("success");
          form.resetFields();
          history.goBack();
        } else {
          setStatus("error");
        }
      });
    });
  };

  if (!selectedInstallation) {
    return null;
  }

  if (status) {
    return (
      <OperationResult
        status={status}
        operation="update"
        entity="installation"
      />
    );
  }
  return (
    <>
      <TitleWithBack
        title={updateInstallationTitle}
        key="update_installation_title"
      />
      <div
        className="my-container my-container-responsive"
        key="update_installation_div"
      >
        <Form layout="vertical" key={1} form={form} name="user_panel">
          {installationFields.map((el) => {
            return (
              <FormInput
                key={el.key + "forminput"}
                value={(selectedInstallation as any)[el.key]}
                keyValue={el.key}
                name={el.name}
                placeholder={el.label}
                type={el.type ?? "text"}
              />
            );
          })}
          <div className="btn-container" key="update_installation_btn">
            <ButtonConfItem
              buttonLabel="Reset"
              buttonOnConfirm={() => form.resetFields()}
              buttonOnCancel={() => {}}
              questionLabel="Il contenuto di tutti i campi sarà cancellato, sei sicuro?"
            />
            <ButtonItem
              buttonType="primary"
              label="Invio"
              buttonOnClick={submit}
            />
          </div>
        </Form>
      </div>
      {width > 575 ? (
        <div className="my-option-container">
          <CardOption installationId={selectedInstallation.id} key="card" />
        </div>
      ) : null}
    </>
  );
};

export default UpdateInstallation;
