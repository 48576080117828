import { BackendUrl, isDev } from "../constants";

export const verticalFetch = (
  method: string,
  url: string,
  param?: string
): Promise<any> => {
  return fetch(url, {
    method: method,
    credentials: "include",
    body: param,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      console.log(error);
    });
};

// used to download files
export const blobFetch = (
  method: string,
  url: string,
  param?: string
): Promise<any> => {
  return fetch(url, {
    method: method,
    body: param,
    headers: {
      Accept: "application/octet-stream",
    },
  })
    .then((response) => response.blob())
    .catch((error) => {
      console.log(error);
    });
};

export const verticalCheck = (
  method: string,
  url: string,
  param?: string
): Promise<any> => {
  return fetch(url, {
    method: method,
    body: param,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        return response.json();
      } else {
        return response;
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const ZDMFetch = (
  method: string,
  url: string,
  param?: string,
  auth?: string
): Promise<any> => {
  return fetch(url, {
    method: method,
    body: param,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-API-KEY": apiKey,
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      console.log(error);
    });
};

const mockBinaryFetch = async (
  method: string,
  url: string
): Promise<ArrayBuffer> => {
  console.log(`Mock fetch called: ${method} ${url}`);

  // Simula un ArrayBuffer come risposta
  const mockData = new Uint8Array([1, 2, 3, 4, 5]).buffer;
  return mockData;
};

export const binaryFetch = (method: string, url: string, param?: string) => {
  const requestOptions: RequestInit = {
    method: method,
    credentials: "include" as RequestCredentials,
    headers: {
      "X-API-KEY": apiKey,
    },
    body: param,
  };
  const mockRequestOptions: RequestInit = {
    method: method,
    body: param,
    headers: {
      Accept: "application/octet-stream",
      "Content-Type": "application/json",
      "X-API-KEY": apiKey,
    },
  };
  if (isDev) {
    return mockBinaryFetch(method, url);
  }
  return fetch(url, isDev ? mockRequestOptions : requestOptions)
    .then((response) => {
      if (response.ok) {
        return response.arrayBuffer();
      }
      throw new Error(response.statusText);
    })
    .catch((error) => console.log(error));
};

export const methodPost = "POST";
export const methodPut = "PUT";
export const methodGet = "GET";
export const methodDelete = "DELETE";

export const workspaceId =
  BackendUrl === "https://api.app.megadiamant.zerynth.com"
    ? "wks-6doumpu2n4fl"
    : "wks-9i0ivdrimkt8";
export const firmwareIdStandard =
  BackendUrl === "https://api.app.megadiamant.zerynth.com"
    ? "fmw-9mcivrz7hcn3"
    : "fmw-9ma5tr74t0n4";
export const firmwareIdPinzaAmperometrica =
  BackendUrl === "https://api.app.megadiamant.zerynth.com"
    ? "fmw-9hf9841a0t6j" //TODO: fare update della nuova versione corretta
    : "fmw-9i471qkq8qdv"; //TODO: fare update della nuova versione corretta
export const apiKey =
  BackendUrl === "https://api.app.megadiamant.zerynth.com"
    ? "Z5TOsWiH2T.6Ot9wlGn7Ogj6hdwv5Qsw7ryhehoeeHtSUmeTtkO8c0zueDj5mRtFSx8V-b0FJm1"
    : "9yWYhSEyLl.jQhHVKBNacV0xWdfGoDbbe7NbyvZign8gj0ZV$Wmbad5g2QvqVxQxDStxG79utCb";
