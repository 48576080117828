import { installationFields } from "./inputs";
import ButtonItem from "../../ButtonItem";
import ButtonConfItem from "../../ButtonConfItem";
import { Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import { CreateInstallation } from "../../../api/services/installationsService";
import { Subscription } from "../../../api/requests/subscription";
import { getAllSubscriptions } from "../../../api/services/subscriptionService";
import CustomTitle from "../../CustomTitle";
import { useHistory } from "react-router-dom";
import { deviceConnectionConfigurationUrl } from "../costants";
import FormInput from "../../input/FormInput";
import { addInstallationTitle } from "../title";
import OperationResult from "../../OperationResult";
import { fw } from "../deviceConnectionConfiguration/costant";

const { Option } = Select;

const AddInstallation: React.FC = () => {
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const [status, setStatus] = useState<"success" | "error" | null>();

  const history = useHistory();

  useEffect(() => {
    getAllSubscriptions("-1", "", "").then((res) => {
      if (res && res.subscriptions) {
        setSubscriptions(res.subscriptions);
      }
    });
  }, []);

  const [form] = Form.useForm();

  const submit = () => {
    form.validateFields().then((values) => {
      CreateInstallation({
        name: values.name,
        subscription_id: values.subscription_id ?? "",
        description: values.description,
        firmware_type: values.firmware_type,
      }).then((res) => {
        if (res && res.installation) {
          form.resetFields();
          history.push(deviceConnectionConfigurationUrl(res.installation.id));
        } else {
          setStatus("error");
        }
      });
    });
  };

  if (status) {
    return (
      <OperationResult
        status={status}
        operation="create"
        entity="installation"
      />
    );
  }

  return (
    <>
      <CustomTitle title={addInstallationTitle} key="add_installation_title" />
      <div
        className="my-container my-container-responsive"
        key="add_installation_div"
      >
        <Form layout="vertical" key={1} form={form} name="user_panel">
          <Form.Item
            label="Seleziona abbonamento"
            name="subscription_id"
            key="select_subscription"
          >
            <Select placeholder="..." key="select">
              {subscriptions.map((el, index) => {
                return (
                  <Option value={el.id} key={index}>
                    {el.company_info.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label={fw.label}
            name={fw.name}
            initialValue={fw.options[0].value}
          >
            <Select placeholder={fw.placeholder}>
              {fw.options.map((opt, i) => {
                return (
                  <Option value={opt.value} key={i} disabled={opt.disabled}>
                    {opt.label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          {installationFields.map((el) => {
            return (
              <FormInput
                key={el.key + "forminput"}
                name={el.name}
                keyValue={el.key}
                placeholder={el.label}
                type={el.type ?? "text"}
                rules={el.rules}
              />
            );
          })}
          <div className="btn-container" key="add_installation_btn">
            <ButtonConfItem
              buttonLabel="Reset"
              buttonOnConfirm={() => {
                form.resetFields();
              }}
              buttonOnCancel={() => {}}
              questionLabel="Il contenuto di tutti i campi sarà cancellato, sei sicuro?"
            />
            <ButtonItem
              buttonType="primary"
              label="Invio"
              buttonOnClick={submit}
            />
          </div>
        </Form>
      </div>
    </>
  );
};

export default AddInstallation;
