import ButtonItem from "../../ButtonItem";
import { Alert, Col, Form, Input, message, Row, Select, Collapse } from "antd";
import React, { useEffect, useState } from "react";
import TitleWithBack from "../../../containers/TitleWithBack";
import { gsm, select, wifi } from "./costant";
import { useHistory } from "react-router";
import {
  isValidIPv4,
  isValidSSID,
  isValidWPA2,
  prepareConfigJson,
  prepareNetJson,
  prepareParamsJsonLochmas,
  prepareParamsJsonPinzaAmperometrica,
  prepareSensorsJsonLochmas,
  prepareSensorsJsonPinzaAmperometrica,
} from "../../../api/constants";
import {
  checkZfsStatus,
  commit,
  getZfs,
  getZfsFile,
  init,
  uploadFiles,
} from "../../../api/services/configuratorService";
import { getInstallation } from "../../../api/services/installationsService";

const { Panel } = Collapse;
const { Option } = Select;

type DeviceUpdateConnectivityProps = {
  installation_id: string;
};

const DeviceUpdateConnectivity: React.FC<DeviceUpdateConnectivityProps> = ({
  installation_id,
}) => {
  const history = useHistory();

  const [form] = Form.useForm();
  const [dhcp, setDhcp] = useState<"manuale" | "automatico">("automatico");
  const [formItem, setFormItem] = useState(select.options[1].value);

  const [net, setNet] = useState<any>({});
  const [error, setError] = useState<string>("");
  const [initialJob, setInitialJob] = useState({
    spd_en: "enabled", //velocita volano
    drag_en: "enabled", //velocita trascinamento,
    am_en: "enabled", //velocita amperometrica
    ta1_wthresh: 0,
    ta2_wthresh: 0,
    ta3_wthresh: 0,
    size: 0,
    ta1_three_phase: true,
    speed_ymin: 0,
    speed_ymax: 100,
  });
  useEffect(() => {
    getZfs(installation_id).then(async (res: any) => {
      if (res && res.files) {
        const netFile = res.files.find((file: any) => file.path === "net.json");
        const paramsFile = res.files.find(
          (file: any) => file.path === "params.json"
        );
        const sensorsFile = res.files.find(
          (file: any) => file.path === "sensors.json"
        );
        if (netFile) {
          getZfsFile(installation_id, netFile.id)
            .then((resFile) => {
              setNet(resFile);
              /*
              setFormItem(
                resFile?.IFCS?.length > 0
                  ? resFile.IFCS[0].ifc_name
                  : select.options[1].value
              );
              */
            })
            .catch((error) => {
              console.error("Error while fetching ZFS file:", error);
              setError(error.message);
            });
        }
        let paramsData: any = {};
        let sensorsData: any = {};

        if (paramsFile) {
          const resFile: any = await getZfsFile(installation_id, paramsFile.id);
          paramsData = {
            spd_en: resFile?.SPD_EN || "enabled",
            drag_en: resFile?.DRAG_EN || "enabled",
            ta1_wthresh: resFile?.TA1?.w_thresh || 0,
            ta2_wthresh: resFile?.TA2?.w_thresh || 0,
            ta3_wthresh: resFile?.TA3?.w_thresh || 0,
            size: resFile?.SIZE || 0,
            ta1_three_phase: resFile?.TA1?.three_phase || true,
            am_en: resFile?.AM_EN || "enabled",
          };
        }

        if (sensorsFile) {
          const resFile: any = await getZfsFile(
            installation_id,
            sensorsFile.id
          );
          sensorsData = {
            speed_ymin: resFile?.SPEED?.conversion?.args?.y_min || 0,
            speed_ymax: resFile?.SPEED?.conversion?.args?.y_max || 100,
          };
        }

        setInitialJob({
          spd_en: paramsData.spd_en || "",
          drag_en: paramsData.drag_en || "",
          ta1_wthresh: paramsData.ta1_wthresh || 0,
          ta2_wthresh: paramsData.ta2_wthresh || 0,
          ta3_wthresh: paramsData.ta3_wthresh || 0,
          size: paramsData.size || 0,
          ta1_three_phase: paramsData.ta1_three_phase ?? true,
          am_en: paramsData.am_en || "enabled",
          speed_ymin: sensorsData.speed_ymin || 0,
          speed_ymax: sensorsData.speed_ymax || 100,
        });
      } else {
        console.log(
          "Errore durante il recupero dei parametri. Riporva più tardi."
        );
      }
    });
  }, []);

  const [firmwareType, setFirmwareType] = useState<
    "standard" | "pinza_amperometrica"
  >("pinza_amperometrica");
  useEffect(() => {
    getInstallation({ id: installation_id }).then((res) => {
      if (res && res.installation) {
        setFirmwareType(res.installation.firmware_type);
      }
    });
  }, []);

  const submit = () => {
    form.validateFields().then(async (values) => {
      console.log(values);
      if (values.connection_type === "wifi") {
        if (!values.sid || !isValidSSID(values.sid)) {
          message.error(
            "SSID non valido. Controllare che il nome della rete Wi-Fi sia corretto."
          );
          return;
        } else if (!values.password || !isValidWPA2(values.password)) {
          message.error(
            "Password non valida. Assicurarsi che la password rispetti i requisiti WPA2."
          );
          return;
        } else if (
          dhcp === "manuale" &&
          (!values.ip || !isValidIPv4(values.ip))
        ) {
          message.error("IP non valido. Inserire un indirizzo IPv4 valido.");
          return;
        } else if (
          dhcp === "manuale" &&
          (!values.subnet || !isValidIPv4(values.subnet))
        ) {
          message.error(
            "Maschera di sottorete non valida. Inserire una subnet mask IPv4 valida."
          );
          return;
        } else if (
          dhcp === "manuale" &&
          (!values.gateway || !isValidIPv4(values.gateway))
        ) {
          message.error("Gateway non valido. Inserire un gateway IPv4 valido.");
          return;
        } else if (
          dhcp === "manuale" &&
          (!values.dns || !isValidIPv4(values.dns))
        ) {
          message.error(
            "DNS non valido. Inserire un indirizzo IPv4 valido per il DNS."
          );
          return;
        }
      }
      const netFile = {
        file: new File(
          [
            new TextEncoder().encode(
              JSON.stringify(
                prepareNetJson(values?.apn || "iot.secure", {
                  ifc_name: "wifi",
                  ifc_params: {
                    ssid: values?.sid || "assistenza",
                    pwd: values?.password || "12345678",
                  },
                })
              )
            ),
          ] as BlobPart[],
          "net.json"
        ),
      };
      const paramsFile = {
        file: new File(
          [
            new TextEncoder().encode(
              JSON.stringify(
                firmwareType === "pinza_amperometrica"
                  ? prepareParamsJsonPinzaAmperometrica(
                      initialJob.ta1_wthresh,
                      initialJob.ta2_wthresh,
                      initialJob.ta3_wthresh,
                      initialJob.spd_en as "enabled" | "disabled",
                      initialJob.drag_en as "enabled" | "disabled",
                      firmwareType === "pinza_amperometrica" ? 1 : 0,
                      initialJob.size
                    )
                  : prepareParamsJsonLochmas(
                      initialJob.ta1_wthresh,
                      initialJob.ta1_three_phase,
                      initialJob.spd_en as "enabled" | "disabled",
                      initialJob.drag_en as "enabled" | "disabled",
                      initialJob.am_en as "enabled" | "disabled"
                    )
              )
            ),
          ] as BlobPart[],
          "params.json"
        ),
      };
      const sensorsFile = {
        file: new File(
          [
            new TextEncoder().encode(
              JSON.stringify(
                firmwareType === "pinza_amperometrica"
                  ? prepareSensorsJsonPinzaAmperometrica
                  : prepareSensorsJsonLochmas(
                      initialJob.speed_ymin,
                      initialJob.speed_ymax
                    )
              )
            ),
          ] as BlobPart[],
          "sensors.json"
        ),
      };
      const configFile = {
        file: new File(
          [
            new TextEncoder().encode(JSON.stringify(prepareConfigJson)),
          ] as BlobPart[],
          "config.json"
        ),
      };

      init(installation_id).then(
        async () =>
          await uploadFiles(installation_id, [
            netFile,
            paramsFile,
            sensorsFile,
            configFile,
          ]).then(async (res: any) => {
            if (res && res.files) {
              await commit(installation_id, true).then((r: any) => {
                if (r && r.version) {
                  message.success("Parametri aggiornati con successo");
                  history.goBack();
                } else {
                  message.error(
                    "Errore durante l'aggiornamento dei parametri. Riprova più tardi."
                  );
                }
              });
            }
          })
      );
    });
  };

  const [runningZfs, setRunningZfs] = useState<boolean>(false);
  useEffect(() => {
    const checkStatus = async () => {
      checkZfsStatus(installation_id)
        .then((res) => {
          if (res && res.job && res.job.status) {
            if (res.job.status === "pending") {
              setRunningZfs(true);
            } else {
              setRunningZfs(false);
            }
          }
        })
        .catch((err) => {
          console.log("ERROR", err);
          setRunningZfs(false);
        });
    };
    checkStatus();
    const intervalId = setInterval(checkStatus, 30000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <TitleWithBack title="Modifica connettività" key={"add_connection"} />
      {runningZfs && (
        <div style={{ width: "100%" }}>
          <Alert
            message="Aggiornamento dei parametri in corso"
            type="warning"
          />
        </div>
      )}
      <div style={{ width: "100%", paddingTop: "8px", paddingBottom: "8px" }}>
        <Alert
          message="ATTENZIONE!"
          type="warning"
          description={
            <>
              <Collapse ghost>
                <Panel header="Verifica requisiti di rete" key="1">
                  <p>
                    Controlla tutti i parametri di connettività prima di
                    procedere: eventuali errori possono compromettere il
                    funzionamento del sistema. Nel caso di rete WiFi, deve
                    rispettare i requisiti di 2.4 GHz con sicurezza WPA2 e
                    garantire stabilità e accessibilità all'impianto.
                  </p>
                  <p style={{ marginTop: 16 }}>
                    Nel caso in cui dopo questa operazione il dispositivo
                    risulti offline, è possibile ristabilire la connessione
                    utilizzando un hotspot con le seguenti credenziali:{" "}
                    <b>assistenza - 12345678</b>.
                  </p>
                </Panel>
              </Collapse>
            </>
          }
        />
      </div>
      <div className="my-container">
        <Form layout="vertical" key={1} name="connection_panel" form={form}>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                label={select.label}
                name={select.name}
                initialValue={
                  /*net?.IFCS?.length > 0
                    ? net.IFCS[0].ifc_name
                    :*/ select.options[1].value
                }
              >
                <Select
                  placeholder={select.placeholder}
                  onChange={(value: string) => {
                    setFormItem(value);
                  }}
                >
                  {select.options.map((opt, i) => {
                    return (
                      <Option value={opt.value} key={i} disabled={opt.disabled}>
                        {opt.label}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {formItem === select.options[0].value ? (
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label={wifi[0].label}
                  name={wifi[0].name}
                  rules={[{ required: true, message: wifi[0].requiredLabel }]}
                  initialValue={
                    net?.IFCS?.length > 0 ? net?.IFCS[0]?.ifc_params?.ssid : ""
                  }
                >
                  <Input
                    placeholder={wifi[0].placeholder}
                    type={wifi[0].type}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={wifi[1].label}
                  name={wifi[1].name}
                  rules={[{ required: true, message: wifi[1].requiredLabel }]}
                  initialValue={
                    net?.IFCS?.length > 0 ? net?.IFCS[0]?.ifc_params?.pwd : ""
                  }
                >
                  <Input
                    placeholder={wifi[1].placeholder}
                    type={wifi[1].type}
                  />
                </Form.Item>
              </Col>
              {/*<Col span={24}>
                <Switch
                  style={{ marginBottom: "20px" }}
                  checked={dhcp === "manuale"}
                  onChange={(value) =>
                    setDhcp(value ? "manuale" : "automatico")
                  }
                  checkedChildren="Ipv4 manuale"
                  unCheckedChildren="Ipv4 automatico"
                />
              </Col>
              {dhcp === "manuale" && (
                <>
                  <Col span={12}>
                    <Form.Item label="Indirizzo IP" name="ip">
                      <Input placeholder="Indirizzo IP" type="string" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Maschera di sottorete" name="subnet">
                      <Input
                        placeholder="Maschera di sottorete"
                        type="string"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Gateway" name="gateway">
                      <Input placeholder="Gateway" type="string" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="DNS" name="dns">
                      <Input placeholder="DNS" type="string" />
                    </Form.Item>
                  </Col>
                </>
              )}*/}
            </Row>
          ) : (
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  label={gsm.label}
                  rules={[{ required: true, message: gsm.requiredLabel }]}
                  name={gsm.name}
                  initialValue={
                    net?.IFCS?.length > 0
                      ? net?.IFCS[0]?.ifc_params?.apn
                      : "iot.secure"
                  }
                >
                  <Select placeholder={gsm.placeholder}>
                    {gsm.options.map((opt, i) => {
                      return (
                        <Option value={opt.value} key={i}>
                          {opt.label}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          )}
          <div className="btn-container">
            <ButtonItem
              buttonType="primary"
              label="Invio"
              buttonOnClick={submit}
              disabled={error || runningZfs ? true : false}
            />
          </div>
        </Form>
      </div>
    </>
  );
};

export default DeviceUpdateConnectivity;
