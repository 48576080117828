import { Alert, Col, Divider, Form, message, Radio, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import TitleWithBack from "../../../containers/TitleWithBack";
import { signalConfigurationTitle } from "../title";
import FormInput from "../../input/FormInput";
import {
  prepareConfigJson,
  prepareParamsJsonLochmas,
  prepareParamsJsonPinzaAmperometrica,
  prepareSensorsJsonLochmas,
  prepareSensorsJsonPinzaAmperometrica,
} from "../../../api/constants";
import {
  checkZfsStatus,
  commit,
  getZfs,
  getZfsFile,
  init,
  uploadFiles,
} from "../../../api/services/configuratorService";
import ButtonConfItem from "../../ButtonConfItem";
import ButtonItem from "../../ButtonItem";
import { getInstallation } from "../../../api/services/installationsService";

type SignalConfigurationTableProps = {
  installation_id: string;
};

const SignalConfigurationTable: React.FC<SignalConfigurationTableProps> = ({
  installation_id,
}) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");
  const [initialJob, setInitialJob] = useState({
    spd_en: "enabled", //velocita volano
    drag_en: "enabled", //velocita trascinamento,
    ta1_wthresh: 0,
    ta2_wthresh: 0,
    ta3_wthresh: 0,
    size: 0,
    am_en: "enabled",
    spd_en_max: 0,
    spd_en_min: 0,
    ta_multi: "enabled",
    ta_threshold: 0,
  });
  useEffect(() => {
    getZfs(installation_id).then(async (res: any) => {
      if (res && res.files) {
        const netFile = res.files.find((file: any) => file.path === "net.json");
        const paramsFile = res.files.find(
          (file: any) => file.path === "params.json"
        );
        const sensorsFile = res.files.find(
          (file: any) => file.path === "sensors.json"
        );
        if (netFile) {
          getZfsFile(installation_id, netFile.id)
            .then((resFile) => {
              setNet(resFile);
            })
            .catch((error) => {
              console.error("Error while fetching ZFS file:", error);
              setError(error.message);
            });
        }
        let paramsData: any = {};
        let sensorsData: any = {};
        if (paramsFile) {
          const resFile: any = await getZfsFile(installation_id, paramsFile.id);
          paramsData = {
            spd_en: resFile?.SPD_EN || "enabled",
            drag_en: resFile?.DRAG_EN || "enabled",
            ta1_wthresh: resFile?.TA1?.w_thresh
              ? resFile?.TA1?.w_thresh / 1000
              : 0,
            ta2_wthresh: resFile?.TA2?.w_thresh
              ? resFile?.TA2?.w_thresh / 1000
              : 0,
            ta3_wthresh: resFile?.TA3?.w_thresh
              ? resFile?.TA3?.w_thresh / 1000
              : 0,
            size: resFile?.SIZE ? resFile?.SIZE / 1000 : 0,
            am_en: resFile?.AM_EN || "enabled",
            ta_multi: resFile?.TA1?.three_phase ? "enabled" : "disabled",
            ta_threshold: resFile?.TA1?.w_thresh
              ? resFile?.TA1?.w_thresh / 1000
              : 0,
          };
        }
        if (sensorsFile) {
          const resFile: any = await getZfsFile(
            installation_id,
            sensorsFile.id
          );
          sensorsData = {
            spd_en_max: resFile?.SPEED?.conversion?.args?.y_max || 0,
            spd_en_min: resFile?.SPEED?.conversion?.args?.y_min || 0,
          };
        }

        setInitialJob({
          spd_en: paramsData.spd_en || "enabled",
          drag_en: paramsData.drag_en || "enabled",
          ta1_wthresh: paramsData.ta1_wthresh || 0,
          ta2_wthresh: paramsData.ta2_wthresh || 0,
          ta3_wthresh: paramsData.ta3_wthresh || 0,
          size: paramsData.size || 0,
          am_en: paramsData.am_en || "enabled",
          spd_en_max: Number(sensorsData.spd_en_max) || 0,
          spd_en_min: Number(sensorsData.spd_en_min) || 0,
          ta_multi: paramsData.ta_multi || "enabled",
          ta_threshold: paramsData.ta_threshold || 0,
        });

        setLoading(false);
      } else {
        console.log(
          "Errore durante il recupero dei parametri. Riporva più tardi."
        );
        setLoading(false);
      }
    });
  }, []);

  const [loadFirmware, setLoadFirmware] = useState<boolean>(true);
  const [firmwareType, setFirmwareType] = useState<
    "standard" | "pinza_amperometrica"
  >("pinza_amperometrica");
  useEffect(() => {
    getInstallation({ id: installation_id }).then((res) => {
      if (res && res.installation) {
        setFirmwareType(res.installation.firmware_type);
      }
      setLoadFirmware(false);
    });
  }, []);

  const [net, setNet] = useState<any>({
    IFCS: [
      {
        ifc_name: "wifi",
        ifc_params: {
          ssid: "assistenza",
          pwd: "12345678",
        },
      },
      {
        ifc_name: "cellular",
        ifc_params: { apn: "iot.secure" },
      },
    ],
  });

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      const netFile = {
        file: new File(
          [new TextEncoder().encode(JSON.stringify(net))] as BlobPart[],
          "net.json"
        ),
      };
      const paramsFile = {
        file: new File(
          [
            new TextEncoder().encode(
              JSON.stringify(
                firmwareType === "pinza_amperometrica"
                  ? prepareParamsJsonPinzaAmperometrica(
                      values.ta1_wthresh * 1000, //da kW a W
                      values.ta2_wthresh * 1000, //da kW a W
                      values.ta3_wthresh * 1000, //da kW a W
                      values.spd_en as "enabled" | "disabled",
                      values.drag_en as "enabled" | "disabled",
                      1,
                      values.size * 1000 //da kW a W
                    )
                  : prepareParamsJsonLochmas(
                      values.ta_threshold * 1000, //da kW a W
                      values.ta_multi === "enabled" ? true : false,
                      values.spd_en as "enabled" | "disabled",
                      values.drag_en as "enabled" | "disabled",
                      values.am_en as "enabled" | "disabled"
                    )
              )
            ),
          ] as BlobPart[],
          "params.json"
        ),
      };
      const sensorsFile = {
        file: new File(
          [
            new TextEncoder().encode(
              JSON.stringify(
                firmwareType === "pinza_amperometrica"
                  ? prepareSensorsJsonPinzaAmperometrica
                  : prepareSensorsJsonLochmas(
                      Number(values?.spd_en_min),
                      Number(values?.spd_en_max)
                    )
              )
            ),
          ] as BlobPart[],
          "sensors.json"
        ),
      };
      const configFile = {
        file: new File(
          [
            new TextEncoder().encode(JSON.stringify(prepareConfigJson)),
          ] as BlobPart[],
          "config.json"
        ),
      };
      init(installation_id).then(
        async () =>
          await uploadFiles(installation_id, [
            netFile,
            paramsFile,
            sensorsFile,
            configFile,
          ]).then(async (res: any) => {
            if (res && res.files) {
              await commit(installation_id, true).then((r: any) => {
                if (r && r.version) {
                  message.success("Parametri aggiornati con successo");
                  history.goBack();
                } else {
                  message.error(
                    "Errore durante l'aggiornamento dei parametri. Riprova più tardi."
                  );
                }
              });
            }
          })
      );
    });
  };

  const [runningZfs, setRunningZfs] = useState<boolean>(false);
  useEffect(() => {
    const checkStatus = async () => {
      checkZfsStatus(installation_id)
        .then((res) => {
          if (res && res.job && res.job.status) {
            if (res.job.status === "pending") {
              setRunningZfs(true);
            } else {
              setRunningZfs(false);
            }
          }
        })
        .catch((err) => {
          console.log("ERROR", err);
          setRunningZfs(false);
        });
    };
    checkStatus();
    const intervalId = setInterval(checkStatus, 30000);
    return () => clearInterval(intervalId);
  }, []);

  if (loading || loadFirmware) {
    return null;
  }

  return (
    <>
      <TitleWithBack
        title={signalConfigurationTitle}
        key="signal_configuration_title"
      />
      {runningZfs && (
        <div style={{ width: "100%" }}>
          <Alert
            message="Aggiornamento dei parametri in corso"
            type="warning"
          />
        </div>
      )}
      <div
        className="my-container my-container-responsive"
        key="signal_configuration_div"
      >
        <Form layout="vertical" key={1} form={form} name="job_panel">
          {firmwareType === "pinza_amperometrica" && (
            <Row gutter={24} align="middle">
              <Col span={12}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  Velocità volano
                  <Form.Item
                    key="spd_en_formitem"
                    name="spd_en"
                    initialValue={initialJob.spd_en}
                  >
                    <Radio.Group key="spd_en" name="spd_en">
                      <Radio value="enabled">Abilitato</Radio>
                      <Radio value="disabled">Disabilitato</Radio>
                    </Radio.Group>
                  </Form.Item>
                </div>
              </Col>
              <Col span={12}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  Velocità trascinamento
                  <Form.Item
                    key="drag_en_formitem"
                    name="drag_en"
                    initialValue={initialJob.drag_en}
                  >
                    <Radio.Group key="drag_en" name="drag_en">
                      <Radio value="enabled">Abilitato</Radio>
                      <Radio value="disabled">Disabilitato</Radio>
                    </Radio.Group>
                  </Form.Item>
                </div>
              </Col>
              <Col span={12}>
                <FormInput
                  key="ta1_wthresh_forminput"
                  keyValue="ta1_wthresh"
                  name="ta1_wthresh"
                  rules={[{ required: true, message: "Campo obbligatorio" }]}
                  placeholder="Soglia di lavoro consumo generale (kW)"
                  type="number"
                  value={initialJob.ta1_wthresh}
                />
              </Col>
              <Col span={12}>
                <FormInput
                  key="ta2_wthresh_forminput"
                  keyValue="ta2_wthresh"
                  name="ta2_wthresh"
                  rules={[{ required: true, message: "Campo obbligatorio" }]}
                  placeholder="Soglia di lavoro volano (kW)"
                  type="number"
                  value={initialJob.ta2_wthresh}
                />
              </Col>
              <Col span={12}>
                <FormInput
                  key="ta3_wthresh_forminput"
                  keyValue="ta3_wthresh"
                  name="ta3_wthresh"
                  rules={[{ required: true, message: "Campo obbligatorio" }]}
                  placeholder="Soglia di lavoro motore di trascinamento (kW)"
                  type="number"
                  value={initialJob.ta3_wthresh}
                />
              </Col>
              <Col span={12}>
                <FormInput
                  key="size_forminput"
                  keyValue="size"
                  name="size"
                  rules={[{ required: true, message: "Campo obbligatorio" }]}
                  placeholder="Taglia macchina (kW)"
                  type="number"
                  value={initialJob.size}
                />
              </Col>
            </Row>
          )}
          {firmwareType === "standard" && (
            <Row gutter={24} align="middle">
              <Col span={12}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  Velocità trascinamento
                  <Form.Item
                    key="drag_en_formitem"
                    name="drag_en"
                    initialValue={initialJob.drag_en}
                  >
                    <Radio.Group key="drag_en" name="drag_en">
                      <Radio value="enabled">Abilitato</Radio>
                      <Radio value="disabled">Disabilitato</Radio>
                    </Radio.Group>
                  </Form.Item>
                </div>
              </Col>
              <Col span={12}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  Automatico manuale
                  <Form.Item
                    key="am_en_formitem"
                    name="am_en"
                    initialValue={initialJob.am_en}
                  >
                    <Radio.Group key="am_en" name="am_en">
                      <Radio value="enabled">Abilitato</Radio>
                      <Radio value="disabled">Disabilitato</Radio>
                    </Radio.Group>
                  </Form.Item>
                </div>
              </Col>
              <Col span={24}>
                <Divider>Velocità volano</Divider>
              </Col>
              <Col span={8}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    paddingTop: "8%",
                  }}
                >
                  Velocità volano
                  <Form.Item
                    key="spd_en_formitem"
                    name="spd_en"
                    initialValue={initialJob.spd_en}
                  >
                    <Radio.Group key="spd_en" name="spd_en">
                      <Radio value="enabled">Abilitato</Radio>
                      <Radio value="disabled">Disabilitato</Radio>
                    </Radio.Group>
                  </Form.Item>
                </div>
              </Col>
              <Col span={8}>
                <FormInput
                  key="spd_en_min_forminput"
                  keyValue="spd_en_min"
                  name="spd_en_min"
                  rules={[{ required: true, message: "Campo obbligatorio" }]}
                  placeholder="Minimo"
                  type="number"
                  value={initialJob.spd_en_min}
                />
              </Col>
              <Col span={8}>
                <FormInput
                  key="spd_en_max_forminput"
                  keyValue="spd_en_max"
                  name="spd_en_max"
                  rules={[{ required: true, message: "Campo obbligatorio" }]}
                  placeholder="Massimo"
                  type="number"
                  value={initialJob.spd_en_max}
                />
              </Col>
              <Col span={24}>
                <Divider>Consumo di potenza</Divider>
              </Col>
              <Col span={12}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    paddingTop: "8%",
                  }}
                >
                  Macchina trifase
                  <Form.Item
                    key="ta_multi_formitem"
                    name="ta_multi"
                    initialValue={initialJob.ta_multi}
                  >
                    <Radio.Group key="ta_multi" name="ta_multi">
                      <Radio value="enabled">Abilitato</Radio>
                      <Radio value="disabled">Disabilitato</Radio>
                    </Radio.Group>
                  </Form.Item>
                </div>
              </Col>
              <Col span={8}>
                <FormInput
                  key="ta_threshold_forminput"
                  keyValue="ta_threshold"
                  name="ta_threshold"
                  rules={[{ required: true, message: "Campo obbligatorio" }]}
                  placeholder="Soglia macchina in lavorazione"
                  type="number"
                  value={initialJob.ta_threshold}
                />
              </Col>
            </Row>
          )}
          <div className="btn-container" key={"add_installation_btn"}>
            <ButtonConfItem
              buttonLabel="Reset"
              buttonOnCancel={() => {}}
              questionLabel="Il contenuto di tutti i campi sarà cancellato, sei sicuro?"
              buttonOnConfirm={() => {
                form.resetFields();
              }}
            />
            <ButtonItem
              buttonType="primary"
              label="Invio"
              buttonOnClick={handleSubmit}
              disabled={error || runningZfs ? true : false}
            />
          </div>
        </Form>
      </div>
    </>
  );
};

export default SignalConfigurationTable;
